<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link to="/" class="d-flex align-center text-decoration-none">
        <v-img
          :src="require('@/assets/images/logos/logo.png')"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <v-slide-x-transition>
          <h1 class="app-title text--primary">TUKUPEDA</h1>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <nav-menu-section-title title="MASTER DATA"></nav-menu-section-title>
    <v-list expand shaped class="vertical-nav-menu-items pr-5">
      <nav-menu-link title="Dashboard" :to="{ name: 'admin-dashboard' }" :icon="icons.mdiHomeOutline"></nav-menu-link>
      <nav-menu-group title="Wilayah" :icon="icons.mdiMapMarkerPath">
        <nav-menu-link title="Provinsi" :to="{ name: 'provinsis-index' }"></nav-menu-link>
        <nav-menu-link title="Kabupaten" :to="{ name: 'kabupatens-index' }"></nav-menu-link>
        <nav-menu-link title="Kecamatan" :to="{ name: 'kecamatans-index' }"></nav-menu-link>
        <nav-menu-link title="Kelurahan" :to="{ name: 'kelurahans-index' }"></nav-menu-link>
      </nav-menu-group>
      <nav-menu-group title="User" :icon="icons.mdiAccountCogOutline">
        <nav-menu-link title="Role" :to="{ name: 'roles-index' }"></nav-menu-link>
        <nav-menu-link title="Kategori Member" :to="{ name: 'kategori-members-index' }"></nav-menu-link>
        <nav-menu-group title="Member" :icon="icons.mdiAccountCogOutline">
          <nav-menu-link title="Member Penjual" :to="{ name: 'members-index' }"></nav-menu-link>
          <nav-menu-link title="Member Pembeli" :to="{ name: 'members-index' }"></nav-menu-link>
        </nav-menu-group>
        <!-- <nav-menu-link title="Clients" :to="{ name: 'clients-index' }"></nav-menu-link>
        <nav-menu-link title="Employees" :to="{ name: 'employees-index' }"></nav-menu-link> -->

        <!-- <nav-menu-link title="Login" :to="{ name: 'admin-login' }" target="_blank"></nav-menu-link>
        <nav-menu-link title="Register" :to="{ name: 'pages-register' }" target="_blank"></nav-menu-link>
        <nav-menu-link title="Error" :to="{ name: 'error-404' }" target="_blank"></nav-menu-link> -->
      </nav-menu-group>
      <nav-menu-group title="Master Data" :icon="icons.mdiArchive">
        <nav-menu-link title="Jenis Usaha" :to="{ name: 'jenisusahas-index' }"></nav-menu-link>
      </nav-menu-group>
      <nav-menu-group title="Product" :icon="icons.mdiStoreSettings">
        <nav-menu-link title="Kategori Produk" :to="{ name: 'kategoriproduks-index' }"></nav-menu-link>
        <nav-menu-link title="Toko" :to="{ name: 'tokos-index' }"></nav-menu-link>
      </nav-menu-group>

      <!-- <nav-menu-group title="Transaction" :icon="icons.mdiCardAccountDetailsStar">
        <nav-menu-link title="Visa" :to="{ name: 'visas-index' }"></nav-menu-link>
      </nav-menu-group> -->

      <!-- <nav-menu-group title="Website" :icon="icons.mdiWeb">
        <nav-menu-link title="Service Category" :to="{ name: 'servicecategory-index' }"></nav-menu-link>
        <nav-menu-link title="Service " :to="{ name: 'service-index' }"></nav-menu-link>
        <nav-menu-link title="Menu" :to="{ name: 'menus-index' }"></nav-menu-link>
        <nav-menu-link title="Static Page" :to="{ name: 'staticpage-index' }"></nav-menu-link>
      </nav-menu-group> -->

      <!-- <nav-menu-link
        title="Account Settings"
        :to="{ name: 'pages-account-settings' }"
        :icon="icons.mdiFileOutline"
      ></nav-menu-link>
      <nav-menu-section-title title="USER INTERFACE"></nav-menu-section-title>
      <nav-menu-link title="Typography" :to="{ name: 'typography' }" :icon="icons.mdiAlphaTBoxOutline"></nav-menu-link>
      <nav-menu-link title="Icons" :to="{ name: 'icons' }" :icon="icons.mdiEyeOutline"></nav-menu-link>
      <nav-menu-link title="Cards" :to="{ name: 'cards' }" :icon="icons.mdiCreditCardOutline"></nav-menu-link>
      <nav-menu-link title="Tables" :to="{ name: 'simple-table' }" :icon="icons.mdiTable"></nav-menu-link>
      <nav-menu-link title="Form Layouts" :to="{ name: 'form-layouts' }" :icon="icons.mdiFormSelect"></nav-menu-link> -->
    </v-list>
    <!-- <a href="https://themeselection.com/products/materio-vuetify-vuejs-admin-template" target="_blank" rel="nofollow">
      <v-img
        :src="require(`@/assets/images/pro/upgrade-banner-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
        alt="upgrade-banner"
        transition="scale-transition"
        class="upgrade-banner mx-auto"
        max-width="230"
      ></v-img>
    </a> -->
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiArchive,
  mdiCashMultiple,
  mdiCardAccountDetailsStar,
  mdiWeb,
  mdiStoreSettings,
  mdiMapMarkerPath,
} from '@mdi/js'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  setup() {
    return {
      icons: {
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiArchive,
        mdiCashMultiple,
        mdiCardAccountDetailsStar,
        mdiWeb,
        mdiStoreSettings,
        mdiMapMarkerPath,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
