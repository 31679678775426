import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/admin/login',
    component: () => import('@/views/admin/Login.vue'),
  },
  {
    path: '/admin/login',
    name: 'admin-login',
    component: () => import('@/views/admin/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/admin/dashboard',
    name: 'admin-dashboard',
    component: () => import('@/views/admin/Dashboard.vue'),
  },
  {
    path: '/admin/roles',
    name: 'roles-index',
    component: () => import('@/views/admin/roles/RolesIndex.vue'),
  },
  {
    path: '/admin/roles/create',
    name: 'roles-create',
    component: () => import('@/views/admin/roles/RolesCreate.vue'),
  },
  {
    path: '/admin/roles/edit/:id_role',
    name: 'roles-edit',
    component: () => import('@/views/admin/roles/RolesEdit.vue'),
  },

  {
    path: '/admin/provinsis',
    name: 'provinsis-index',
    component: () => import('@/views/admin/provinsis/ProvinsisIndex.vue'),
  },
  {
    path: '/admin/provinsis/create',
    name: 'provinsis-create',
    component: () => import('@/views/admin/provinsis/ProvinsisCreate.vue'),
  },
  {
    path: '/admin/provinsis/edit/:id_provinsi',
    name: 'provinsis-edit',
    component: () => import('@/views/admin/provinsis/ProvinsisEdit.vue'),
  },

  {
    path: '/admin/kabupatens',
    name: 'kabupatens-index',
    component: () => import('@/views/admin/kabupatens/KabupatensIndex.vue'),
  },
  {
    path: '/admin/kabupatens/create',
    name: 'kabupatens-create',
    component: () => import('@/views/admin/kabupatens/KabupatensCreate.vue'),
  },
  {
    path: '/admin/kabupatens/edit/:id_kabupaten',
    name: 'kabupatens-edit',
    component: () => import('@/views/admin/kabupatens/KabupatensEdit.vue'),
  },
  {
    path: '/admin/kecamatans',
    name: 'kecamatans-index',
    component: () => import('@/views/admin/kecamatans/KecamatansIndex.vue'),
  },
  {
    path: '/admin/kecamatans/create',
    name: 'kecamatans-create',
    component: () => import('@/views/admin/kecamatans/KecamatansCreate.vue'),
  },
  {
    path: '/admin/kecamatans/edit/:id_kecamatan',
    name: 'kecamatans-edit',
    component: () => import('@/views/admin/kecamatans/KecamatansEdit.vue'),
  },
  {
    path: '/admin/kelurahans',
    name: 'kelurahans-index',
    component: () => import('@/views/admin/kelurahans/KelurahansIndex.vue'),
  },
  {
    path: '/admin/kelurahans/create',
    name: 'kelurahans-create',
    component: () => import('@/views/admin/kelurahans/KelurahansCreate.vue'),
  },
  {
    path: '/admin/kelurahans/edit/:id_kelurahan',
    name: 'kelurahans-edit',
    component: () => import('@/views/admin/kelurahans/KelurahansEdit.vue'),
  },
  {
    path: '/admin/kategori-members',
    name: 'kategori-members-index',
    component: () => import('@/views/admin/kategorimembers/KategoriMembersIndex.vue'),
  },
  {
    path: '/admin/kategori-members/create',
    name: 'kategori-members-create',
    component: () => import('@/views/admin/kategorimembers/KategoriMembersCreate.vue'),
  },
  {
    path: '/admin/kategori-members/edit/:id_kategorimember',
    name: 'kategori-members-edit',
    component: () => import('@/views/admin/kategorimembers/KategoriMembersEdit.vue'),
  },
  {
    path: '/admin/members',
    name: 'members-index',
    component: () => import('@/views/admin/members/MembersIndex.vue'),
  },
  {
    path: '/admin/members/create',
    name: 'members-create',
    component: () => import('@/views/admin/members/MembersCreate.vue'),
  },
  {
    path: '/admin/members/edit/:id_member',
    name: 'members-edit',
    component: () => import('@/views/admin/members/MembersEdit.vue'),
  },
  {
    path: '/admin/jenisusahas',
    name: 'jenisusahas-index',
    component: () => import('@/views/admin/jenisusahas/JenisUsahaIndex.vue'),
  },
  {
    path: '/admin/jenisusahas/create',
    name: 'jenisusahas-create',
    component: () => import('@/views/admin/jenisusahas/JenisUsahaCreate.vue'),
  },
  {
    path: '/admin/jenisusahas/edit/:id_jenisusaha',
    name: 'jenisusahas-edit',
    component: () => import('@/views/admin/jenisusahas/JenisUsahaEdit.vue'),
  },
  {
    path: '/admin/kategori-produks',
    name: 'kategoriproduks-index',
    component: () => import('@/views/admin/kategoriproduks/KategoriProdukIndex.vue'),
  },
  {
    path: '/admin/kategori-produks/create',
    name: 'kategoriproduks-create',
    component: () => import('@/views/admin/kategoriproduks/KategoriProdukCreate.vue'),
  },
  {
    path: '/admin/kategori-produks/edit/:id_kategoriproduk',
    name: 'kategoriproduks-edit',
    component: () => import('@/views/admin/kategoriproduks/KategoriProdukEdit.vue'),
  },
  {
    path: '/admin/tokos',
    name: 'tokos-index',
    component: () => import('@/views/admin/tokos/TokosIndex.vue'),
  },
  {
    path: '/admin/tokos/create',
    name: 'tokos-create',
    component: () => import('@/views/admin/tokos/TokosCreate.vue'),
  },
  {
    path: '/admin/tokos/produk-barang/:id_toko/:id_jenisusaha',
    name: 'tokos-produk-barang',
    component: () => import('@/views/admin/tokos/TokosProdukBarang.vue'),
  },
  {
    path: '/admin/tokos/produk-jasa/:id_toko/:id_jenisusaha',
    name: 'tokos-produk-jasa',
    component: () => import('@/views/admin/tokos/TokosProdukJasa.vue'),
  },
  {
    path: '/admin/tokos/produk-barang-edit/:id_produk/:id_produkbarang',
    name: 'tokos-produk-barang-edit',
    component: () => import('@/views/admin/tokos/TokosProdukBarangEdit.vue'),
  },
  {
    path: '/admin/tokos/produk-jasa-edit/:id_produk/:id_produkjasa',
    name: 'tokos-produk-jasa-edit',
    component: () => import('@/views/admin/tokos/TokosProdukJasaEdit.vue'),
  },
  {
    path: '/admin/tokos/toko-jadwal/:id_toko',
    name: 'tokos-jadwals',
    component: () => import('@/views/admin/jadwals/JadwalIndex.vue'),
  },
  {
    path: '/admin/tokos/toko-jadwal-edit/:id_jadwal/:id_toko',
    name: 'tokos-jadwal-edit',
    component: () => import('@/views/admin/jadwals/JadwalEdit.vue'),
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
